import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/vsts/work/1/s/src/components/default-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`KROHNE – Data Privacy Statement and Information pursuant to Article 13 GDPR`}</h1>
    <p>{`We at KROHNE want you to know that we care about your privacy. We want you to know exactly how we use the information you provide to us in the course of using our Website. By accessing and using our Website, you agree to be bound by the terms of this Privacy Notice and the terms of our Internet Disclaimer and Statement. If you are not willing to agree to these terms, please do not use our Website.`}</p>
    <p>{`You are not required to provide any personal information in order to visit our Website or to obtain the information that is freely available to all visitors. However, in order to fully utilise our services – i.e., to configure products to your specifications and to order our products from our e-shop – you must register with us.`}</p>
    <p>{`If and when you decide to register, you will be asked to provide your e-mail address and other information necessary to process your order. Except as otherwise stated herein, we do not provide that information to third parties.`}</p>
    <p>{`We are committed to maintaining the trust of our users. If you have any questions or concerns about the privacy or security of any information that you have provided, please do not hesitate to contact us.`}</p>
    <p><strong parentName="p">{`Handling of Information`}</strong></p>
    <p>{`We retain and store all information you voluntarily transmit to us when using our Website ("User Information"). You are, of course, free not to provide any of the information that you are prompted to provide when utilizing particular features of our Website, but you should bear in mind that your failure to provide us with certain information may affect your ability to use such features and/or to order products through our e-shop.`}</p>
    <p>{`We use the User Information for purposes such as servicing your orders, responding to your questions and requests, and improving and personalizing our communications with you. Except as stated below, we do not sell or otherwise distribute or transfer to any third parties any User Information and we keep all User Information in confidence.`}</p>
    <p>{`In order to fill your e-shop orders and to optimize our services for you, we reserve the right to share your User Information with our subsidiaries and affiliates, our suppliers, our agents, dealers of our products, credit reporting agencies and fraud protection services, entities supporting our web services including this Website and any other persons or entities to whom we must provide your User Information in order to comply with applicable law. We may also share your User Information with carriers or other persons and organisations involved in the delivery and servicing of your order. We reserve the right to transfer any User Information in connection with a sale or other transfer of our business or assets.`}</p>
    <p><strong parentName="p">{`Subscription to our Newsletter`}</strong></p>
    <p>{`KROHNE offers various newsletters that you can order for free. If you wish to subscribe to our newsletter(s), we need the confirmation that you are the owner of the given e-mail address and that you agree to receive the newsletter(s). These data are collected only for the purpose of being able to send you the newsletter and to document our authorization in this regard.`}</p>
    <p>{`During the registration process, you will receive an e-mail with a confirmation link. A transfer of the data to third parties does not take place. The subscription of the newsletter and your consent to the storage of the e-mail address can be revoked at any time for the future. For statistical purposes, we perform anonymous link tracking. If you have any questions, please contact us by e-mail: `}<a parentName="p" {...{
        "href": "mailto:newsletter.DE@krohne.com"
      }}>{`newsletter.DE@krohne.com`}</a>{`.`}</p>
    <p><strong parentName="p">{`Cookies`}</strong></p>
    <p>{`Cookies help us to personalise your future visits to our Website and to provide you features that we think will be of interest to you. Cookies also help to prevent you from being asked to register or made to re-enter your password every time you visit our site.`}</p>
    <p>{`Most browsers offer you information about how to avoid, alter and/or identify and remove cookies. You are, of course, free to delete from your hard drive any cookies that we have issued to you. However, your future visits to our Website may then be less personalised and convenient.`}</p>
    <p><strong parentName="p">{`etracker`}</strong></p>
    <p>{`KROHNE uses the services of etracker GmbH, Hamburg, Germany (`}<a parentName="p" {...{
        "href": "http://www.etracker.com"
      }}>{`www.etracker.com`}</a>{`) to analyse usage data. Here, cookies are used which enable the statistical analysis of the use of this website by its visitors as well as the display of usage-relevant content or advertising. Cookies are small text files that are stored by the Internet browser on the user's device. etracker cookies do not contain any information that could identify a user.`}</p>
    <p>{`The data generated with etracker is processed and stored by etracker solely in Germany by commission of the provider of this website and is thus subject to strict German and European data protection laws and standards.In this regard, etracker was checked, certified and awarded with the ePrivacyseal data protection seal of approval.`}</p>
    <p>{`The data is processed on the legal basis of Article 6(1)(f) oder Art. 6 para. 1 letter f (legitimate interest) of the EU General Data Protection Regulation (GDPR) .Our legitimate interest is the optimization of our online offer and our website. As the privacy of our visitors is very important to us, etracker anonymizes the IP address as early as possible and converts login or device IDs into a unique key with which, however,no connection to any specific person can be made with. etracker does not use it for any other purpose, combine it with other data or pass it on to third parties.`}</p>
    <p>{`You can object to the outlined data processing at any time provided it is related to your person. Your objection has no detrimental consequences for you. I object to the processing of my personal data with etracker on this website.`}</p>
    <p>{`Further information on data protection with etracker can be found here`}</p>
    <p><strong parentName="p">{`Advertising and conversion cookies`}</strong></p>
    <p>{`Advertising and conversion cookies are used to make on other sites outside our offer advertising more relevant for you, e.g. based on your interests. Also, this prevents that the same advertisements are displayed again to you. With these cookies, we can also measure the effectiveness of our advertising campaigns based on anonymous and pseudonymous user profiles and understand how users interact with our advertisements and what actions the users have implemented after interacting with the respective advertising material (so-called "conversion").`}</p>
    <p><strong parentName="p">{`Google (re)marketing services`}</strong></p>
    <p>{`For the purposes of our legitimate interests (i.e. our interest in analyzing, optimizing and running our Websites in a commercially viable manner within the meaning of Art. 6 (1) f. of the GDPR), we use the marketing and remarketing services (hereinafter referred to as "Google marketing services") provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ("Google").`}</p>
    <p>{`Google is certified under the Privacy Shield framework which offers a guarantee of compliance with European data protection legislation`}</p>
    <p>{`(`}<a parentName="p" {...{
        "href": "https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
      }}>{`https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active`}</a>{`).`}</p>
    <p>{`Google marketing services enable us to display ads for and on our website in a more targeted fashion, helping us to only show ads to Users that are potentially of interest to them. The method we use, known as remarketing, involves, for example, showing Users ads for products in which they have already shown an interest on other websites.`}</p>
    <p>{`For this purpose, our Websites – and other websites on which Google marketing services are active – contain a snippet of code, which is executed directly by Google. This integrates what are known as (re)marketing tags in the website (invisible image files or code, also known as web beacons). With the help of these tags, an individual cookie, i.e. a small file, is saved on the User’s device (comparable technologies may also be used instead). These cookies may be set from a few different domains, including google.com, invitemedia.com, admeld.com, googlesyndication.com and googleadservices.com.`}</p>
    <p>{`This file notes which sites the User visits, which content interests the User, and which offers he or she clicked, as well as technical information on the browser and operating system, referring websites, visit duration and other data on the use of the Websites.`}</p>
    <p>{`The User’s IP address is also recorded, though we wish to make it clear that, within the context of Google Analytics, the IP address is truncated within European Union Member States and in other countries that are party to the Agreement on the European Economic Area. Only in exceptional cases is the full IP address transferred to the US-based Google server and truncated there.`}</p>
    <p>{`The IP address is not merged with User data within other Google offerings or services. The information referred to above may also be linked to comparable information from other sources. If the User subsequently visits other websites, they may be presented with ads tailored to them according to their interests.`}</p>
    <p>{`User data is processed in a pseudonymized manner within the context of Google marketing services, i.e. Google does not store and process details such as the name or email address of the User, but instead processes the relevant data within pseudonymized usage profiles based on cookies. This means that, from Google’s perspective, the ads are not managed for and displayed to a named or otherwise identifiable person, but rather for and to the cookie holder, regardless of who this cookie holder is. That is not, however, the case if a User has expressly granted Google permission to process their data in a non-pseudonymized manner.`}</p>
    <p>{`Information collected on Users by Google marketing services is transmitted to Google and stored on Google’s servers in the USA.`}</p>
    <p><strong parentName="p">{`Google AdWords`}</strong></p>
    <p>{`As part of Google AdWords, we use the so-called conversion tracking. When you click on an ad served by Google, a conversion tracking cookie is set. Cookies are small text files that the Internet browser stores on the user’s computer. These cookies lose their validity after 30 days and are not used for the personal identification of the users. If the user visits certain pages of this website and the cookie has not expired yet, Google and we can recognize that the user clicked on the ad and was redirected to this page.`}</p>
    <p>{`Google Adwords Conversion Tracking: Within the Google AdWords service, we use the conversion tracking function as well. A "conversion" is a specific action of the user (e.g. ordering a newsletter, registering on the site), e.g. when you click on an ad we have placed (on Google itself, so-called "Google Ads", or on other sites within the Google Display Network). The information that is collected using the conversion cookie is used to create conversion statistics for AdWords customers. Each Google AdWords customer receives a different cookie. The cookies cannot be tracked through the websites of advertisers. The information gathered using the conversion cookie is used to generate conversion statistics for AdWords advertisers who have opted for conversion tracking. Customers are told the total number of users who clicked on their ad and were redirected to a conversion tracking tag page. However, they do not receive information that personally identifies users. You can disable interest-based ads on Google as well as interest-based Google ads on the web (within the Google Display Network) in your browser by clicking the “Off” button under adssettings.google.com. For more information on your Google’s setting options and privacy, visit policies.google.com/privacy and policies.google.com/technologies/ads. The storage of "conversion cookies" is based on Article 6(1)(f) oder Art. 6 para. 1 letter f of GDPR. The website operator has a legitimate interest in analysing user behavior in order to optimise both its website and its advertising.`}</p>
    <p>{`You can find more information on how Google uses data for marketing purposes at`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.google.com/policies/technologies/ads"
      }}>{`https://www.google.com/policies/technologies/ads`}</a>{`.`}</p>
    <p>{`Google’s privacy policy is available at`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.google.com/policies/privacy"
      }}>{`https://www.google.com/policies/privacy`}</a>{`.`}</p>
    <p>{`If you wish to opt out of personalized advertising by Google marketing services, you can adjust your preferences and opt-out settings by visiting the following page: `}<a parentName="p" {...{
        "href": "http://www.google.com/ads/preferences"
      }}>{`http://www.google.com/ads/preferences`}</a></p>
    <p><strong parentName="p">{`Seznam.cz`}</strong></p>
    <p>{`Our webpage uses retargeting technologies of the „Sklik“ service, provided by Seznam.cz, a.s. This service enables to display our advertisements in the advertising network of Seznam.cz, a.s., to the visitors, who have already shown interested in our products.
You can find detailed information about the privacy policy of Seznam.cz here: o.seznam.cz/ochrana-udaju/`}</p>
    <p><strong parentName="p">{`LinkedIn`}</strong></p>
    <p>{`LinkedIn Conversion Tracking: On our website, we use the analysis and conversion tracking technology of the LinkedIn platform. This LinkedIn technology allows you to view more relevant ads based on your interests. We also provide LinkedIn with aggregated and anonymous reporting of ad activities and information about how you interact with our website. For more information on LinkedIn privacy, please visit `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/legal/privacy-policy#choices-oblig"
      }}>{`https://www.linkedin.com/legal/privacy-policy#choices-oblig`}</a>{`.`}</p>
    <p>{`You can disregard the analysis of your usage behavior by LinkedIn as well as the display of interest-based recommendations (“opt-out of retargeting”): Click the “Reject on LinkedIn button” (for LinkedIn members) or Reject (for other users) at the following link:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/psettings/guest-controls/retargeting-opt-outs"
      }}>{`https://www.linkedin.com/psettings/guest-controls/retargeting-opt-outs`}</a>{`.`}</p>
    <p><strong parentName="p">{`Security`}</strong></p>
    <p>{`The use of certain features of our Website require you to select a user name and password. It is important for you to protect the confidentiality of your user name and password and to protect against any unauthorized use of your computer or network. We have no technical or other means to protect you from any unauthorized use of your user name and password.`}</p>
    <p>{`While we have taken reasonable steps to protect the security of User Information, we cannot guarantee that your User Information will remain permanently secure. There is always a possibility that your User Information could be lost due to technical problems or stolen from our databases. We are not responsible for any such loss or theft or for any other unanticipated security breaches.`}</p>
    <p><strong parentName="p">{`Server log-files`}</strong></p>
    <p>{`The provider of the pages automatically collects and stores information in so-called server log files, which your browser automatically transmits to us. These are:`}</p>
    <ul>
      <li parentName="ul">{`Browser type and browser version`}</li>
      <li parentName="ul">{`Used operating system`}</li>
      <li parentName="ul">{`Referrer URL`}</li>
      <li parentName="ul">{`Host name of the accessing computer`}</li>
      <li parentName="ul">{`Time of the server request`}</li>
    </ul>
    <p>{`These data cannot be assigned to specific persons. A merge of this data with other data sources will not be done. We reserve the right to check this data retrospectively, if we become aware of specific indications for illegal use.`}</p>
    <p><strong parentName="p">{`TLS encryption`}</strong></p>
    <p>{`This site uses TLS encryption for security reasons and to protect the transmission of sensitive content, such as the requests you send to us as the site operator. You can recognize an encrypted connection by changing the address line of the browser from “http: //” to “https: //” and the lock symbol in your browser line. If TLS encryption is enabled, the data you submit to us can not be read by third parties.`}</p>
    <p><strong parentName="p">{`Access to Your User Information`}</strong></p>
    <p>{`If you wish to know what information we have stored about you or your business please contact us at `}<a parentName="p" {...{
        "href": "mailto:web@krohne.com."
      }}>{`web@krohne.com.`}</a>{` At any time you may instruct us to permanently delete any such information.`}</p>
    <p><strong parentName="p">{`Modifications to Privacy Notice`}</strong></p>
    <p>{`We reserve the right to modify this Privacy Notice and our Internet Disclaimer and Statement at any time. You should check this Privacy Notice and our Internet Disclaimer and Statement periodically for changes. If at any time you choose not to accept these terms and conditions, please do not continue to use our Website.`}</p>
    <p><strong parentName="p">{`Contact person for data protection`}</strong></p>
    <p>{`Should you have any questions or comments regarding data protection or wish to object to the use of your personal data for advertising, market and opinion research purposes, please write to the following address:`}</p>
    <p><strong parentName="p">{`Head office KROHNE Group`}</strong>{`
`}<strong parentName="p">{`KROHNE Messtechnik GmbH`}</strong>{`
`}<strong parentName="p">{`Data protection officer Peter Suhling`}</strong>{`
`}<strong parentName="p">{`Ludwig-Krohne-Str. 5`}</strong>{`
`}<strong parentName="p">{`47058 Duisburg`}</strong>{`
`}<strong parentName="p">{`Germany`}</strong></p>
    <p>{`E-Mail: `}<a parentName="p" {...{
        "href": "mailto:datenschutz@krohne.com"
      }}>{`datenschutz@krohne.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      